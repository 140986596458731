<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Survey response
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\  Survey response
                </div>
              </div>
              <div class="breadcrumb-right">
                <b-dropdown size="sm" variant="link" toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret right no-flip>
                  <template v-slot:button-content>
                    <i class="ki ki-bold-more-hor"></i>
                  </template>
                  <!--begin::Navigation-->
                  <div class="navi navi-hover min-w-md-250px">
                    <b-dropdown-text tag="div" class="navi-item">
                      <a @click="exportToCsv()"  class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-file-csv"></i>
                                </span>
                        <span class="navi-text">Export (CSV)</span>
                      </a>
                    </b-dropdown-text>
                  </div>
                  <!--end::Navigation-->
                </b-dropdown>

              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="10">
                  <v-autocomplete
                      :items="survey_groups"
                      item-text="title"
                      item-value="id"
                      label="Survey group"
                      clearable :loading="surveyGroupLoading"
                      v-model="search.survey_group_id"
                      v-on:keyup.enter="searchSurveyResponses()"
                      @input="search.survey_group_id = $event !== null ? $event : ''"
                      dense
                      outlined>
                  </v-autocomplete>
                </v-col>
                <v-col cols="2" class="text-right">
                  <v-btn
                      :loading="loading"
                      @click.prevent="searchSurveyResponses()"
                      class="btn btn-primary w-35 float-right"
                      dark
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table" v-if="! loading">
                <thead>
                <tr class="px-3">
                  <th class="px-3">Survey question</th>
                  <th class="px-3">Survey group</th>
                  <th class="px-3">Rating</th>


                </tr>
                </thead>

                <tbody>
                  <tr v-for="(item, index) in surveryResponses" :key="index">

                    <td class="px-3">
                     <p v-html="item.question"></p>
                    </td>
                    <td class="px-3">
                      <div class="">
                        {{ item.survey_group_name }}
                      </div>
                    </td>
                    <td class="px-3">
                      <div class="">
                        {{ item.average_rating }}
                      </div>
                    </td>

                  </tr>
                  <tr v-if="surveryResponses.length === 0">
                    <td class="text-center px-3" colspan="8">No items found.</td>
                  </tr>
                </tbody>
              </table>
              <b-pagination
                  v-if="surveryResponses.length > 0"
                  class="pull-right mt-7"
                  @input="getAllSurveyResponses"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  :disabled="loading"
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script >
import CandidateQuestionnaireResponseService
  from "@/services/candidate/candidate-questionnaire-response/CandidateQuestionnaireResponseService";
import SurveyGroupService from "@/services/survey-groups/SurveyGroupService";
import {API_URL} from "@/core/config";

const surveyGroup=new SurveyGroupService();
const candidateQuestionnaireResponse=new CandidateQuestionnaireResponseService();
export default {
  data(){
    return{
      surveryResponses:[],
      loading:false,
      total: null,
      perPage: null,
      page: null,
      survey_groups:[],
      surveyGroupLoading:false,
      search:{
        survey_group_id:null,
      }
    }
  },
  methods:{
    exportToCsv(){
      if(this.search.survey_group_id){
        let url = "";
        if (this.search != null && this.search != undefined && this.search != "") {
          var queryString = Object.keys(this.search)
              .map(key => {
                return (encodeURIComponent(key) + "=" + encodeURIComponent(this.search[key]));
              }).join("&");
          if (queryString) {
            url = url + "?" + queryString;
          }
        }
        window.open(
            API_URL + "admin/candidate-questionnaire-response/export/csv" + url,
            "_blank"
        );
      }else{
        this.$snotify.warning("Select any survey group");
      }

    },
    searchSurveyResponses(){
      this.page=1;
      this.getAllSurveyResponses();
    },
    getAllSurveyResponses(){
      candidateQuestionnaireResponse
          .listAllQuestionsWithAverageRating(this.search, this.page)
          .then(response => {
            this.surveryResponses = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          })
          .finally(() => {
            this.loading=false;
          })
    },
    getAllSurveyGroups() {
      this.surveyGroupLoading=true;
      surveyGroup
          .getAllActiveSurveyGroup()
          .then(response => {
            this.survey_groups = response.data.surveyGroups;
          })
          .catch((err) => {

          })
          .finally(() => {
            this.surveyGroupLoading = false;
          });
    },
  },
  mounted() {
    this.getAllSurveyResponses();
    this.getAllSurveyGroups();
  },
  watch: {

    page(newPage) {
      this.getAllSurveyResponses();
    },
  },
}
</script>